import { UserInterface } from '@/types/User'
import {PublicationInterface} from "@/types/Publication";

export interface DossierInscription {
    id: number | 0,
    publication_id: number | 0,
    publication?: PublicationInterface | null,
    compte_candidat_id: number | 0,
    creation_methode: CreationMethode,
    compteCandidat: CompteCandidat,
    datas: any,
    etat: Etat,
    etat_updated_at: Date | string | null,
    motif: string | null,
    user_id: number | 0,
    user: UserInterface,
    submitted_at: Date | string | null,
    transfered_at: Date | string | null
}

export interface CompteCandidat {
    id: number | 0,
    code: number | 0,
    name: string | '',
    usual_name: string | '',
    first_name: string | '',
    middle_name: string | '',
    email: string | ''
}

export interface EtatDossier {
    index: number | 0,
    libelle: string | '',
    color: string | ''
}

export interface ParamsTrie {
    direction: string | '',
    sort: string | '',
    page: number | 0
}

export enum Etat {
    ETAT_NON_DEPOSE,
    ETAT_DEPOSE,
    ETAT_VALIDE,
    ETAT_RELANCE,
    ETAT_REJETE,
}

export enum CreationMethode {
    METHODE_PORTAIL = 1,
    METHODE_ADMIN = 2,
}

export function getEtatDossier(etat: Etat): EtatDossier {
    switch (etat) {
        default:
            return { index: 0, libelle: 'Non déposé', color: 'secondary' }
        case Etat.ETAT_DEPOSE:
            return { index: 1, libelle: 'Déposé', color: 'primary' }
        case Etat.ETAT_VALIDE:
            return { index: 2, libelle: 'Vérifié', color: 'success' }
        case Etat.ETAT_RELANCE:
            return { index: 3, libelle: 'Relancé', color: 'warning' }
        case Etat.ETAT_REJETE:
            return { index: 4, libelle: 'Écarté', color: 'danger' }
    }
}

export function getCreationMethode(creationMethode: CreationMethode): string {
    switch (creationMethode) {
        default:
            return '-'
        case CreationMethode.METHODE_PORTAIL:
            return 'Candidat'
        case CreationMethode.METHODE_ADMIN:
            return 'Gestionnaire'
    }
}
