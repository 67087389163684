





























































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { codeRestrict } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { ConcourInterface } from '@/types/Concour'

@Component({
    components: {
        Table,
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('formulaire', ['loading', 'error', 'formulaires', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class Formulaires extends Vue {
    codeRestrict = codeRestrict
    // DATAS
    params: any = {
        sort: 'name',
        direction: 'asc'
    }

    dataForTab: Array<any> = []
    filtres: any = []

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'validFormulaire', label: 'Formulaire validé', sortable: false, class: 'text-center', type: 'action' },
        { key: 'cloneFormulaire', label: '', sortable: false, class: '', type: 'action' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]

    formulaire: any = null
    formulaireTemp: any = null
    Ability = Ability
    modeClone = false

    showModalEditionFormulaire = false
    showModalMessageDelete = false
    showModalValideFormulaire = false

    // ---- generic table --------------------------
    // ----------
    listeConcours: Array<ConcourInterface> = []
    listeSelectConcours: Array<ConcourInterface> = []
    concour_temp_id: any = null

    // --------------------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const classEtatEdit = 'text-light col-w-etat btn_action_ligne'
                const iconEtatEdit = this.$store.getters['auth/can'](Ability.CAND_FORMINSC_MANAGE) ? 'pen' : 'eye'

                const iconValidFormulaire = result.validated_at === null ? 'times' : 'check'
                const titleValidFormulaire =
                    result.validated_at === null ? 'Formulaire non validé' : 'Formulaire validé'
                const classValidFormulaire =
                    result.validated_at === null
                        ? 'text-center text-secondary col-w-action bg-transparent'
                        : 'text-center text-success col-w-action bg-transparent'

                const line = [
                    {
                        label: '',
                        item: result,
                        icon: iconEtatEdit,
                        type: 'action',
                        typeAction: 'etatEdit',
                        class: classEtatEdit,
                        disabled: false
                    },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    {
                        label: titleValidFormulaire,
                        item: result,
                        icon: iconValidFormulaire,
                        type: 'action',
                        typeAction: 'validFormulaire',
                        class: classValidFormulaire,
                        disabled: !this.$store.getters['auth/can'](Ability.CAND_FORMINSC_MANAGE)
                    },
                    {
                        label: 'Cloner le formulaire',
                        item: result,
                        icon: ['far', 'clone'],
                        type: 'action',
                        typeAction: 'cloneFormulaire',
                        class: '',
                        disabled: !this.$store.getters['auth/can'](Ability.CAND_FORMINSC_MANAGE)
                    }
                ]

                if (this.$store.getters['auth/can'](Ability.CAND_FORMINSC_MANAGE)) {
                    const trashLine = {
                        label: 'Supprimer',
                        item: result,
                        type: 'action',
                        typeAction: 'delete',
                        class: 'text-secondary',
                        icon: 'trash-alt',
                        disabled: false
                    }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = [
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    break
                case 'openComment':
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'etatEdit':
                    this.editFormulaire(paParams[1])
                    break
                case 'delete':
                    this.deleteFormulaire(paParams[1])
                    break
                case 'validFormulaire':
                    if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                        this.valideFormulaire(paParams[1])
                    }
                    break
                case 'cloneFormulaire':
                    this.openClonerFormulaire(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /**
     * valideFormulaire
     * Cette fonction permet de valider un formulaire.
     * @param {any} data - Données du formulaire à valider
     * @returns {void}
     */
    valideFormulaire(data: any): void {
        this.formulaire = data
        this.formulaireTemp = {
            id: this.formulaire.id,
            name: this.formulaire.name,
            datas: this.formulaire.datas,
            validated_at: this.formulaire.validated_at
        }
        this.showModalValideFormulaire = true
    }

    /**
     * cancelValideFormulaire
     * Cette fonction annule la validation du formulaire en réinitialisant les données du formulaire et en fermant la fenêtre modale.
     * @returns {void}
     */
    cancelValideFormulaire(): void {
        this.formulaireTemp = null
        this.showModalValideFormulaire = false
    }

    /**
     * valideFormulaireSuite
     * Cette fonction permet de valider et de mettre à jour un formulaire.
     * @returns {void}
     */
    valideFormulaireSuite(): void {
        const payload = {
            id: this.formulaireTemp.id,
            name: this.formulaireTemp.name,
            datas: this.formulaireTemp.datas,
            validate: this.formulaireTemp.validated_at === null ? 1 : 0
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('formulaire/updateFormulaire', payload)
            .then(() => {
                this.formulaire = null
                this.formulaireTemp = null
                this.showModalValideFormulaire = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifiée avec succès !', succesToaster)
                this.$store.dispatch('formulaire/getFormulaires', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.formulaire.formulaires)
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * openAddFormulaire
     * Cette fonction ouvre une fenêtre modale pour ajouter un formulaire.
     * @returns {void}
     */
    openAddFormulaire(): void {
        this.listeConcours = []
        this.listeSelectConcours = []
        this.concour_temp_id = null
        this.$store
            .dispatch('concour/getConcours', {
                session_id: this.$store.state.session.sessionSelect.id,
                params: { 'filter-is_formulaire': 0, 'filter-is_banque': 0 }
            })
            .then(() => {
                this.listeConcours = this.$store.getters['concour/concours']
                this.formulaireTemp = {
                    id: 0,
                    datas: null,
                    name: '',
                    concour_id: null
                }
                this.modeClone = false
                this.showModalEditionFormulaire = true
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * Fonction openClonerFormulaire
     * Cette fonction est utilisée pour cloner un formulaire.
     * @param {any} formulaire - Formulaire à cloner
     * @returns {void}
     */
    openClonerFormulaire(formulaire: any): void {
        console.log('openClonerFormulaire')
        this.listeConcours = []
        this.listeSelectConcours = []
        this.concour_temp_id = null

        this.$store
            .dispatch('concour/getConcours', {
                session_id: this.$store.state.session.sessionSelect.id,
                params: { 'filter-is_formulaire': 0, 'filter-is_banque': 0 }
            })
            .then(() => {
                this.listeConcours = this.$store.getters['concour/concours']
                this.formulaireTemp = {
                    id: formulaire.id,
                    datas: null,
                    name: formulaire.name,
                    concour_id: null
                }
                this.modeClone = true
                this.showModalEditionFormulaire = true
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * ajouteConcour
     * Cette fonction permet d'ajouter un concours à la liste des concours sélectionnés.
     * @returns {void}
     */
    ajouteConcour(): void {
        if (this.concour_temp_id !== null) {
            const listeConcoursTemmp: Array<ConcourInterface> = []
            for (let i = 0; i < this.listeConcours.length; i++) {
                if (this.listeConcours[i].id !== this.concour_temp_id) {
                    listeConcoursTemmp.push(this.listeConcours[i])
                } else {
                    this.listeSelectConcours.push(this.listeConcours[i])
                }
            }
            this.listeSelectConcours.sort((a: any, b: any) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : a.banque.code > b.banque.code ? 1 : -1
            })
            this.listeConcours = listeConcoursTemmp
            this.concour_temp_id = null
        }
    }

    /**
     * deleteConcour
     * Cette fonction permet de supprimer un concours de la liste des concours sélectionnés.
     * @param {number} concourId - Identifiant du concours à supprimer
     * @returns {void}
     */
    deleteConcour(concourId: number): void {
        if (concourId !== null) {
            const listeConcoursTemmp: Array<ConcourInterface> = []
            for (let i = 0; i < this.listeSelectConcours.length; i++) {
                if (this.listeSelectConcours[i].id !== concourId) {
                    listeConcoursTemmp.push(this.listeSelectConcours[i])
                } else {
                    this.listeConcours.push(this.listeSelectConcours[i])
                }
            }
            this.listeConcours.sort((a: any, b: any) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : a.banque.code > b.banque.code ? 1 : -1
            })

            this.listeSelectConcours = listeConcoursTemmp
            this.listeSelectConcours.sort((a: any, b: any) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : a.banque.code > b.banque.code ? 1 : -1
            })

            this.concour_temp_id = null
        }
    }

    /**
     * editFormulaire
     * Cette fonction permet d'éditer un formulaire en redirigeant vers la page d'édition du formulaire correspondant.
     * @param {any} data - Données du formulaire à éditer
     * @returns {void}
     */
    editFormulaire(data: any): void {
        /*
        this.formulaire = data
        this.formulaireTemp = {
            id: this.formulaire.id,
            name: this.formulaire.name,
            datas: this.formulaire.datas
        }
        this.showModalEditionFormulaire = true
        */
        this.$store.state.contrattype.error = null
        this.$router.push('/formulaires_inscriptions/' + data.id)
    }

    /**
     * cancelEdit
     * Cette fonction annule l'édition du formulaire en réinitialisant les données du formulaire et en fermant la fenêtre modale.
     * @returns {void}
     */
    cancelEdit(): void {
        this.formulaire = null
        this.formulaireTemp = null
        this.showModalEditionFormulaire = false
        this.modeClone = false
    }

    /**
     * Fonction editSuite
     * Cette fonction est utilisée pour effectuer une édition ou un clonage d'un formulaire.
     * @returns {void}
     */
    editSuite(): void {
        const concoursIds: Array<any> = []
        for (let i = 0; i < this.listeSelectConcours.length; i++) {
            concoursIds.push(this.listeSelectConcours[i].id)
        }
        const payload = {
            id: this.formulaireTemp.id,
            name: this.formulaireTemp.name,
            datas: JSON.parse('{"libelle":"' + this.formulaireTemp.name + '","description":"","parties":[]}'),
            concours: concoursIds
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.modeClone) {
            this.$store.dispatch('formulaire/cloneFormulaire', payload)
                .then(() => {
                    this.formulaire = null
                    this.formulaireTemp = null
                    this.showModalEditionFormulaire = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Formulaire cloné avec succès !', succesToaster)
                    this.$store.dispatch('formulaire/getFormulaires', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.formulaire.formulaires)
                        })
                        .catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('formulaire/addFormulaire', payload)
                .then(() => {
                    this.formulaire = null
                    this.formulaireTemp = null
                    this.showModalEditionFormulaire = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Formulaire ajouté avec succès !', succesToaster)
                    this.$store.dispatch('formulaire/getFormulaires', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.formulaire.formulaires)
                        })
                        .catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /**
     * deleteFormulaire
     * Cette fonction permet de supprimer un formulaire.
     * @param {any} data - Données du formulaire à supprimer
     * @returns {void}
     */
    deleteFormulaire(data: any): void {
        this.formulaire = data
        this.showModalMessageDelete = true
    }

    /**
     * cancelDelete
     * Cette fonction annule la suppression du formulaire en réinitialisant les données du formulaire et en fermant la fenêtre modale.
     * @returns {void}
     */
    cancelDelete(): void {
        this.formulaire = null
        this.showModalMessageDelete = false
    }

    /**
     * deleteSuite
     * Cette fonction permet de supprimer un formulaire.
     * @returns {void}
     */
    deleteSuite(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('formulaire/deleteFormulaire', this.formulaire.id)
            .then(() => {
                this.formulaire = null
                this.formulaireTemp = null
                this.showModalEditionFormulaire = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire supprimé avec succès !', succesToaster)
                this.formulaire = null
                this.showModalMessageDelete = false
                this.$store.dispatch('formulaire/getFormulaires', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.formulaire.formulaires)
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * loadHandler
     * Cette fonction permet de gérer le chargement de données supplémentaires pour les formulaires.
     * @param {any} params - Paramètres de chargement des données supplémentaires
     * @returns {void}
     */
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store
                .dispatch('formulaire/getMoreFormulaires', params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.state.formulaire.formulaires)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * filtreSortHandler
     * Cette fonction permet de gérer le filtrage et le tri des formulaires.
     * @param {any} params - Paramètres de filtrage et de tri des formulaires
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store
                .dispatch('formulaire/getFormulaires', params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.state.formulaire.formulaires)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    /**
     * mounted
     * Initialisation des filtres pour l'onglet générique.
     * @returns {void}
     */
    mounted(): void {
        this.setFiltersForGenericTab()
    }
}
