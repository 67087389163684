
































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
import { dateDuJourFoDocument, formatDate, getFileNameFromHeader } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import {
    CreationMethode,
    DossierInscription,
    Etat,
    EtatDossier,
    getCreationMethode,
    getEtatDossier
} from '@/types/DossierInscription'
import Back from '@/components/Tools/Back.vue'
import CreateDossier from '@/components/Inscriptions/CreateDossier.vue'
import { PublicationInterface } from '@/types/Publication'
import {FormulaireInterface} from "@/types/Formulaire";

@Component({
    components: {
        Back,
        ExaGenericTable,
        CreateDossier
    },
    computed: {
        Ability() {
            return Ability
        },
        ...mapGetters({
            can: 'auth/can',
            loading_dossiers: 'dossierinscription/loading',
            dossiers: 'dossierinscription/dossiers',
            meta: 'dossierinscription/meta',
            loading_formulaire: 'formulaire/loading',
            formulaire: 'formulaire/formulaireSelect'
        })
    },
    watch: {
        dossiers: {
            handler: 'onDossiersChange',
            deep: true,
            immediate: true
        }
    }
})
export default class Formulaires extends Vue {
    datas: any[] = []
    filters: any[] = []
    modale = false
    prevent_reload = true
    loading_export = false
    fields = [
        { key: 'edit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'id', label: 'Numéro', sortable: true, sortDirection: 'asc', class: '', type: 'text' },
        { key: 'compteCandidat.name', label: 'Nom', sortable: false, class: '', type: 'text' },
        {
            key: 'compteCandidat.first_name',
            label: 'Prénom',
            sortable: false,
            class: '',
            type: 'text'
        },
        { key: 'compteCandidat.email', label: 'Email', sortable: false, class: '', type: 'text' },
        { key: 'etat', label: 'État', sortable: true, sortDirection: 'asc', class: '', type: 'text' },
        { key: 'submitted_at', label: 'Date de dépôt', sortable: true, sortDirection: 'asc', class: '', type: 'text' },
        {
            key: 'etat_updated_at',
            label: 'Date de décision',
            sortable: true,
            sortDirection: 'asc',
            class: '',
            type: 'text'
        },
        { key: 'user.name', label: 'Modifié par', sortable: false, class: '', type: 'text' },
        { key: 'creation_methode', label: 'Créé par', sortable: true, sortDirection: 'asc', class: '', type: 'text' },
        { key: 'transfered_at', label: 'Transféré le', sortable: true, sortDirection: 'asc', class: '', type: 'text' }
    ]

    /**
     * Chargement de la liste des dossiers
     */
    onDossiersChange(dossiers: DossierInscription[]): void {
        this.datas = []
        if (!dossiers || !dossiers.length) {
            return
        }

        for (let i = 0; i < dossiers.length; i++) {
            const state: EtatDossier = getEtatDossier(dossiers[i].etat)
            this.datas.push([
                {
                    label: 'Éditer',
                    item: dossiers[i].id,
                    icon: 'pen',
                    type: 'action',
                    typeAction: 'edit',
                    class: 'text-center text-light',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].id,
                    type: 'text',
                    typeAction: null,
                    class: '',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].compteCandidat.name,
                    type: 'text',
                    typeAction: null,
                    class: '',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].compteCandidat.first_name,
                    type: 'text',
                    typeAction: null,
                    class: '',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].compteCandidat.email,
                    type: 'text',
                    typeAction: null,
                    class: '',
                    disabled: false
                },
                {
                    label: '',
                    item: state.libelle,
                    type: 'text',
                    typeAction: null,
                    class: `text-${state.color}`,
                    disabled: false
                },
                {
                    label: '',
                    item: formatDate(dossiers[i].submitted_at),
                    type: 'text',
                    typeAction: null,
                    class: '',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].etat_updated_at ? formatDate(dossiers[i].etat_updated_at) : '-',
                    type: 'text',
                    typeAction: null,
                    class: dossiers[i].etat_updated_at ? '' : 'text-secondary',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].user ? `${dossiers[i].user.name} ${dossiers[i].user.first_name}` : '-',
                    type: 'text',
                    typeAction: null,
                    class: dossiers[i].user ? '' : 'text-secondary',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].creation_methode ? getCreationMethode(dossiers[i].creation_methode) : '-',
                    type: 'text',
                    typeAction: null,
                    class: dossiers[i].creation_methode ? '' : 'text-secondary',
                    disabled: false
                },
                {
                    label: '',
                    item: dossiers[i].transfered_at ? formatDate(dossiers[i].transfered_at) : '-',
                    type: 'text',
                    typeAction: null,
                    class: dossiers[i].user ? '' : 'text-secondary',
                    disabled: false
                }
            ])
        }

        const dossier: DossierInscription = this.$store.getters['dossierinscription/dossier']
        if (dossier) {
            const index = dossiers.findIndex((dossierInscription: DossierInscription) => {
                return dossierInscription.id === dossier.id
            })
            if (index !== -1) {
                this.$nextTick(() => {
                    const element = document.getElementsByClassName('commons_table_line')[index]
                    if (element) {
                        element.scrollIntoView({ block: 'end' })
                    }
                })
            }
            this.$store.commit('dossierinscription/SET_DOSSIER', {} as DossierInscription)
        }
    }

    /**
     * loadDossiers
     * Chargement de la liste des dossiers d'inscription
     * @returns {Promise<void>}
     */
    async loadDossiers(): Promise<void> {
        await this.$store.dispatch('dossierinscription/getDossiersInscription', {
            publication_id: parseInt(this.$route.params.publication_id),
            payload: this.$store.getters['dossierinscription/params']
        })
        return Promise.resolve()
    }

    exportDossiersInscription() {
        if (this.loading_export) {
            return
        }
        this.loading_export = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('dossierinscription/getExportDossiersInscription', {
            publication_id: parseInt(this.$route.params.publication_id)
        })
            .then((response) => {
                const fileName = getFileNameFromHeader(response.headers) ?? `dossier_inscription_${this.$store.getters['formulaire/formulaireSelect'].name}.xlsx`
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.loading_export = false
            })
    }

    /**
     * Chargement des filtres
     * @returns {void}
     */
    loadFiltres(): void {
        // Chargement des filtres
        const etats = []
        for (let i = 0; i < Object.keys(Etat).length / 2; i++) {
            etats.push({ index: i, name: getEtatDossier(i).libelle })
        }
        this.filters = [
            {
                libelle: 'identifiant',
                model: 'id',
                value: '',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'id', strict: true }
            },
            {
                libelle: 'nom',
                model: 'compteCandidat.name',
                value: '',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'compteCandidat.name', strict: false }
            },
            {
                libelle: 'prénom',
                model: 'compteCandidat.first_name',
                value: '',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'compteCandidat.first_name', strict: false }
            },
            {
                libelle: 'email',
                model: 'compteCandidat.email',
                value: '',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'compteCandidat.email', strict: false }
            },
            {
                libelle: 'état',
                model: 'etat',
                value: '',
                datas: etats,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'etat', strict: true }
            },
            {
                libelle: 'méthode de création',
                model: 'creation_methode',
                value: '',
                datas: [
                    {
                        index: CreationMethode.METHODE_PORTAIL,
                        name: getCreationMethode(CreationMethode.METHODE_PORTAIL)
                    },
                    {
                        index: CreationMethode.METHODE_ADMIN,
                        name: getCreationMethode(CreationMethode.METHODE_ADMIN)
                    }
                ],
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'creation_methode', strict: true }
            }
        ]

        // Récupération des filtres enregistrés dans le store
        const params = this.$store.getters['dossierinscription/params']
        for (let i = 0; i < this.filters.length; i++) {
            let filter = params[`filter-${this.filters[i].model}`]
            if (filter) {
                filter = this.filters[i].options.strict ? filter : filter.substring(1, filter.length - 1)
                this.filters[i].value = this.filters[i].options.type === 'deroulant' ? parseInt(filter) : filter
            }
        }
    }

    /**
     * Récupération des events de la table
     * @param {any} paParams - Paramètres de l'événement
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    if (
                        JSON.stringify(this.$store.getters['dossierinscription/params']) !== JSON.stringify(paParams[1])
                    ) {
                        this.$store.commit('dossierinscription/SET_PARAMS', paParams[1])
                        if (this.prevent_reload) {
                            this.prevent_reload = false
                            return
                        }
                        await this.loadDossiers()
                    }
                    break
                case 'edit':
                    await this.$router.push(
                        `/dossiers_inscriptions/${this.$route.params.publication_id}/${paParams[1]}`
                    )
                    break
                case 'onLoadPage':
                    await this.$store.dispatch('dossierinscription/getMoreDossiersInscription', {
                        publication_id: parseInt(this.$route.params.publication_id),
                        payload: paParams[1]
                    })
                    break
                default:
                    break
            }
        }
    }

    /**
     * beforeMount
     * Séquence de chargement des données avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        // Vérification du formulaire enregistré dans le store
        if (!this.$store.getters['publication/get_publications_inscriptions'].length) {
            await this.$store.dispatch('publication/getPublications')
        }

        const formulaire_id: number = this.$store.getters['publication/get_publications_inscriptions']
            .filter((publication: PublicationInterface) => {
                return publication.id === parseInt(this.$route.params.publication_id)
            })[0].entity_id ?? 0

        const formulaire: FormulaireInterface = this.$store.getters['formulaire/formulaireSelect']
        if (!formulaire || formulaire.id !== formulaire_id) {
            await this.$store.dispatch('formulaire/getFormulaire', { formulaire_id: formulaire_id })
        }

        // Vérification des dossiers d'inscription enregistrés dans le store
        const dossiers: DossierInscription[] = this.$store.getters['dossierinscription/dossiers']
        if (!dossiers || this.$store.getters['dossierinscription/publication_id'] !== parseInt(this.$route.params.publication_id)) {
            await this.loadDossiers()
        }

        // Chargement des filtres
        this.loadFiltres()
    }
}
