































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Back from '@/components/Tools/Back.vue'
import formulaire from '@exatech-group/formulaire/src/Formulaire.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { ConcourInterface } from '@/types/Concour'
import { isObject } from '@/utils/helpers'

@Component({
    components: {
        formulaire,
        Back,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('session', ['sessionActive']),
        ...mapGetters('formulaire', ['formulaireSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class EditionFormulaire extends Vue {
    Ability = Ability

    formulaireTemp: any = null
    showModalValideFormulaire = false

    listeConcours: Array<ConcourInterface> = []
    listeSelectConcours: Array<ConcourInterface> = []
    showModalEditionConcours = false
    concour_temp_id: any = null
    piloteFormulaire: any = null
    concoursFormulaire: any = null

    /**
     * openEditConcourFormulaire
     * Cette fonction ouvre une fenêtre modale pour éditer les concours d'un formulaire.
     */
    openEditConcourFormulaire() {
        this.listeConcours = []
        this.listeSelectConcours = this.formulaireTemp.concours
        this.concour_temp_id = null
        this.$store
            .dispatch('concour/getConcours', {
                session_id: this.$store.state.session.sessionSelect.id,
                params: { 'filter-is_formulaire': 0, 'filter-is_banque': 0 }
            })
            .then(() => {
                this.listeConcours = this.$store.getters['concour/concours']
                this.showModalEditionConcours = true
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * ajouteConcour
     * Cette fonction permet d'ajouter un concours à la liste des concours sélectionnés.
     */
    ajouteConcour() {
        if (this.concour_temp_id !== null) {
            const listeConcoursTemmp: Array<ConcourInterface> = []
            for (let i = 0; i < this.listeConcours.length; i++) {
                if (this.listeConcours[i].id !== this.concour_temp_id) {
                    listeConcoursTemmp.push(this.listeConcours[i])
                } else {
                    this.listeSelectConcours.push(this.listeConcours[i])
                }
            }
            this.listeSelectConcours.sort((a: any, b: any) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : a.banque.code > b.banque.code ? 1 : -1
            })
            this.listeConcours = listeConcoursTemmp
            this.concour_temp_id = null
        }
    }

    /**
     * deleteConcour
     * Cette fonction permet de supprimer un concours de la liste des concours sélectionnés.
     * @param {number} concourId - identifiant du concours à supprimer
     */
    deleteConcour(concourId: number) {
        if (concourId !== null) {
            const listeConcoursTemmp: Array<ConcourInterface> = []
            for (let i = 0; i < this.listeSelectConcours.length; i++) {
                if (this.listeSelectConcours[i].id !== concourId) {
                    listeConcoursTemmp.push(this.listeSelectConcours[i])
                } else {
                    this.listeConcours.push(this.listeSelectConcours[i])
                }
            }
            this.listeConcours.sort((a: any, b: any) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : a.banque.code > b.banque.code ? 1 : -1
            })

            this.listeSelectConcours = listeConcoursTemmp
            this.listeSelectConcours.sort((a: any, b: any) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : a.banque.code > b.banque.code ? 1 : -1
            })
            this.concour_temp_id = null
        }
    }

    /**
     * cancelEditConcoursFormulaire
     * Cette fonction annule l'édition des concours d'un formulaire en fermant la fenêtre modale.
     */
    cancelEditConcoursFormulaire() {
        this.showModalEditionConcours = false
    }

    /**
     * editConcourFormulaireSuite
     * Cette fonction permet de modifier les concours d'un formulaire.
     */
    editConcourFormulaireSuite() {
        this.formulaireTemp.concours = this.listeSelectConcours
        this.showModalEditionConcours = false
        const concoursTemp: Array<any> = []
        for (let i = 0; i < this.formulaireTemp.concours.length; i++) {
            concoursTemp.push(this.formulaireTemp.concours[i].id)
        }
        const payload = {
            id: this.formulaireTemp.id,
            concours: concoursTemp
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('formulaire/updateConcoursFormulaire', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Formulaire modifié avec succès !', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * storeDocumentReference
     * Cette fonction permet de stocker un document référence dans le formulaire en cours.
     * @param {string} titre - Titre du document
     * @param {any} data - Données du document à stocker
     * @returns une promesse résolue avec l'UUID du document stockée
     */
    async storeDocumentReference(titre: string, data: any) {
        const formdata = new FormData()
        formdata.set('document', data)
        formdata.set('name', titre)
        return new Promise((resolve) => {
            this.$store
                .dispatch('formulaire/storeDocumentReference', {
                    payload: formdata,
                    formulaire_id: this.formulaireTemp.id
                })
                .then((response: any) => {
                    resolve({ id: response.data.data.uuid })
                })
        })
    }

    /**
     * getDocumentReference
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à récupérer
     * @returns une promesse résolue avec le document récupéré
     */
    getDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('formulaire/getDocumentReference', {
                    formulaire_id: this.formulaireTemp.id,
                    documentId: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * deleteDocumentReference
     * Cette fonction permet de supprimer un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à supprimer
     * @returns une promesse résolue avec la réponse de la suppression du document
     */
    deleteDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('formulaire/deleteDocumentReference', {
                    formulaire_id: this.formulaireTemp.id,
                    documentId: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * updateFormulaire
     * Cette fonction met à jour le formulaire avec les valeurs fournies.
     * @param {any} value - Nouvelle valeur du formulaire
     */
    updateFormulaire(value: any) {
        if (this.$store.state.formulaire.formulaireSelect === null) {
            this.formulaireTemp = {
                id: 0,
                session_id: this.$store.state.session.sessionSelect.id,
                name: '',
                datas: null
            }
        }
        this.formulaireTemp.name = JSON.parse(value).libelle
        this.formulaireTemp.datas = value
    }

    /**
     * getPiloteFormulaire
     * Cette fonction récupère le pilote du formulaire en cours.
     */
    getPiloteFormulaire() {
        this.$store
            .dispatch('formulaire/getPiloteFormulaire', { formulaire_id: this.$route.params.publication_id })
            .then(() => {
                this.piloteFormulaire = { data: this.$store.state.formulaire.piloteFormulaireSelect }
            })
        this.$store
            .dispatch('formulaire/getConcoursFormulaire', { formulaire_id: this.$route.params.publication_id })
            .then(() => {
                this.concoursFormulaire = { data: this.$store.state.formulaire.concoursFormulaireSelect }
            })
    }

    /**
     * saveFormulaire
     * Cette fonction permet d'enregistrer ou de mettre à jour un formulaire.
     */
    saveFormulaire() {
        const concoursTemp: Array<any> = []
        for (let i = 0; i < this.formulaireTemp.concours.length; i++) {
            concoursTemp.push(this.formulaireTemp.concours[i].id)
        }
        const payload = {
            id: this.formulaireTemp.id,
            session_id: this.formulaireTemp.session_id,
            name: isObject(this.formulaireTemp.datas)
                ? this.formulaireTemp.datas.libelle
                : JSON.parse(this.formulaireTemp.datas).libelle,
            datas: isObject(this.formulaireTemp.datas)
                ? this.formulaireTemp.datas
                : JSON.parse(this.formulaireTemp.datas),
            concours: concoursTemp
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.formulaireTemp.id === 0) {
            this.$store.dispatch('formulaire/addFormulaire', payload)
                .then((response) => {
                    this.formulaireTemp.id = response.data.data.id
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Formulaire ajoutée avec succès !', succesToaster)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('formulaire/updateFormulaire', payload)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Formulaire modifié avec succès !', succesToaster)
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    /**
     * valideFormulaire
     * Cette fonction ouvre une fenêtre modale pour valider le formulaire.
     */
    valideFormulaire() {
        this.showModalValideFormulaire = true
    }

    /**
     * cancelValideFormulaire
     * Cette fonction annule la validation du formulaire en fermant la fenêtre modale.
     */
    cancelValideFormulaire() {
        this.showModalValideFormulaire = false
    }

    /**
     * valideFormulaireSuite
     * Cette fonction permet de valider le formulaire en cours.
     */
    valideFormulaireSuite() {
        const payload = {
            id: this.formulaireTemp.id,
            name: isObject(this.formulaireTemp.datas)
                ? this.formulaireTemp.datas.libelle
                : JSON.parse(this.formulaireTemp.datas).libelle,
            datas: isObject(this.formulaireTemp.datas)
                ? this.formulaireTemp.datas
                : JSON.parse(this.formulaireTemp.datas),
            validate: this.formulaireTemp.validated_at === null ? 1 : 0
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('formulaire/updateFormulaire', payload)
            .then((response: any) => {
                this.formulaireTemp.validated_at = response.data.data.validated_at
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.showModalValideFormulaire = false
                this.$bvToast.toast('Formulaire modifiée avec succès !', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * beforeMount
     * Cette fonction est exécutée avant que le composant ne soit monté.
     */
    beforeMount() {
        if (this.$route.params.publication_id !== '0') {
            this.$store
                .dispatch('formulaire/getFormulaire', { formulaire_id: this.$route.params.publication_id })
                .then(() => {
                    if (this.$store.state.formulaire.formulaireSelect) {
                        this.formulaireTemp = JSON.parse(JSON.stringify(this.$store.state.formulaire.formulaireSelect))
                        this.getPiloteFormulaire()
                    } else {
                        this.formulaireTemp = {
                            id: 0,
                            session_id: this.$store.state.session.sessionSelect.id,
                            name: '',
                            datas: null
                        }
                    }
                })
        } else {
            this.formulaireTemp = {
                id: 0,
                session_id: this.$store.state.session.sessionSelect.id,
                name: '',
                datas: null
            }
        }
    }
}
