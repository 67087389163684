

































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {DossierInscription, Etat} from '@/types/DossierInscription'
import {mapGetters} from "vuex";
import {dateNow} from "@/utils/helpers";

@Component({
    components: {
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters({
            dossier: 'dossierinscription/dossier',
            formulaire: 'formulaire/formulaireSelect'
        }),
        etat() {
            return Etat
        }
    },
    watch: {
        statut: {
            handler: 'onStatutChange',
            immediate: true
        },
        modale: {
            handler: 'onModaleChange'
        }
    }
})
export default class CreateDossier extends Vue {
    @Prop({ required: true }) readonly statut!: number | null

    modale = false
    motif = ''
    loading = false

    /**
     * onStatutChange
     * Ouvre ou ferme la modale en fonction du statut
     * @return void
     */
    onStatutChange(): void {
        this.modale = this.statut !== null
    }

    /**
     * onModaleChange
     * Émet l'événement de fermeture de la modale
     * @return {Promise<void>}
     */
    async onModaleChange(): Promise<void> {
        if (this.modale) {
            const dossier = this.$store.getters['dossierinscription/dossier']
            this.motif = this.statut === dossier.etat && dossier.motif ? dossier.motif : ''
        } else {
            this.motif = ''
            this.loading = false
            this.$emit('close')
        }
    }


    /**
     * updateStatutDossier
     * Met à jour le statut du dossier d'inscription
     * @return {Promise<void>}
     */
    async updateStatutDossier(): Promise<void> {
        if (this.statut && !this.loading) {
            this.loading = true

            const dossier: DossierInscription = JSON.parse(JSON.stringify(this.$store.getters['dossierinscription/dossier']))
            dossier.etat = this.statut
            dossier.etat_updated_at = dateNow()
            dossier.user = this.$store.getters['auth/authUser']
            dossier.user_id = dossier.user.id

            let payload: { etat: number; motif?: string } = { etat: dossier.etat }
            if (this.statut > Etat.ETAT_VALIDE && this.motif) {
                dossier.motif = this.motif
                payload = { ...payload, motif: dossier.motif }
            }

            try {
                await this.$store
                    .dispatch('dossierinscription/putEtatDossierInscription', {
                        dossier_inscription_id: dossier.id,
                        payload: payload
                    })
            } finally {
                this.$emit('close')
            }
        }
    }
}
