









































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { DossierInscription } from '@/types/DossierInscription'
import { CompteCandidatInterface } from '@/types/CompteCandidat'
import {FormulaireInterface} from "@/types/Formulaire";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

@Component({
    components: {
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters({
            formulaire: 'formulaire/formulaireSelect',
            candidats: 'comptecandidat/comptes',
            loading_candidats: 'comptecandidat/loading'
        }),
        confirm_btn() {
            const formulaire: FormulaireInterface = this.$store.getters['formulaire/formulaireSelect']

            return (
                // Chargement en cours
                this.$store.getters['comptecandidat/loading'] ||
                this.$data.loading ||
                // Validation du formulaire nécessaire
                (formulaire && !formulaire.validated_at) ||
                // Aucun candidat sélectionné
                (this.$data.new_candidat === false && !this.$data.candidat_code) ||
                // Candidat sélectionné, mais déjà un dossier
                this.$data.duplicate ||
                // Champs du nouveau candidat incomplets
                (typeof this.$data.new_candidat === 'object' &&
                    !(
                        this.$data.new_candidat.name.trim() &&
                        this.$data.new_candidat.first_name.trim() &&
                        this.$data.new_candidat.email.trim()
                    ))
            )
        }
    },
    watch: {
        opened: {
            handler: 'onOpenedChange',
            immediate: true
        },
        modale: {
            handler: 'onModaleChange'
        },
        candidat: {
            handler: 'onCandidatChange'
        },
        new_candidat: {
            handler: 'onNewCandidatChange'
        }
    }
})
export default class CreateDossier extends Vue {
    @Prop({ type: Boolean, required: true, default: false }) readonly opened!: boolean
    @Prop({ type: Number, required: true, default: 0 }) readonly publication_id!: number

    modale = false
    formulaire_id = 0
    loading = false
    duplicate = false
    candidat = ''
    candidat_code = 0
    new_candidat: { name: string; first_name: string; email: string } | boolean = false

    /**
     * onOpenedChange
     * Mise à jour de l'état de la modale
     * @return {void}
     */
    onOpenedChange(): void {
        this.modale = this.opened
    }

    /**
     * onOpenedChange
     * Mise à jour des variables en fonction de l'état d'ouverture de la modale
     * @return {Promise<void>}
     */
    async onModaleChange(): Promise<void> {
        if (this.modale) {
            await this.loadCandidats()

            // Écran de création d'un nouveau candidat si aucun candidat enregistré
            if (!this.$store.getters['comptecandidat/comptes'].length) {
                this.new_candidat = {
                    name: '',
                    first_name: '',
                    email: ''
                }
            }
        } else {
            // Réinitialisation des variables
            this.formulaire_id = 0
            this.loading = false
            this.duplicate = false
            this.candidat = ''
            this.candidat_code = 0
            this.new_candidat = false
            this.$emit('close')
        }
    }

    /**
     * onCandidatChange
     * Vérification de l'existence du candidat lors de la sélection par recherche
     * @returns {void}
     */
    onCandidatChange(): void {
        this.candidat_code = 0
        this.duplicate = false

        if (this.candidat.trim()) {
            const candidats: CompteCandidatInterface[] = this.$store.getters['comptecandidat/comptes']
            for (let i = 0; i < candidats.length; i++) {
                if (`${candidats[i].name} ${candidats[i].first_name}` === this.candidat) {
                    this.candidat_code = candidats[i].code
                    break
                }
            }

            if (this.candidat_code) {
                const dossiers_inscriptions: DossierInscription[] = this.$store.getters['dossierinscription/dossiers']
                for (let i = 0; i < dossiers_inscriptions.length; i++) {
                    if (dossiers_inscriptions[i].compteCandidat.code === this.candidat_code) {
                        this.duplicate = true
                        break
                    }
                }
            }
        }
    }

    /**
     * onNewCandidatChange
     * Réinitialisation de la sélection du candidat à la création d'un nouveau
     * @returns {void}
     */
    onNewCandidatChange(): void {
        this.candidat = ''
    }

    /**
     * loadCandidats
     * Charge les candidats dans le store
     * @returns {Promise<void>}
     */
    async loadCandidats(): Promise<void> {
        await this.$store.dispatch('comptecandidat/getCompteCandidats', { sort: 'name', direction: 'asc', perPage: 0, 'filter-not_in_publication': this.publication_id })
        return Promise.resolve()
    }

    /**
     * Création d'un nouveau dossier
     * @returns {Promise<void>}
     */
    async createDossier(): Promise<void> {
        this.loading = true
        let payload: { code: string } | { name: string; first_name: string; email: string }

        // Récupération des informations du candidat
        if (this.candidat_code && !this.duplicate) {
            payload = { code: this.candidat_code.toString() }
        } else if (typeof this.new_candidat === 'object') {
            payload = {
                name: this.new_candidat.name,
                first_name: this.new_candidat.first_name,
                email: this.new_candidat.email
            }
        } else {
            return
        }

        try {
            // Création du dossier et redirection vers le mode édition
            const response_post = await this.$store
                .dispatch('dossierinscription/postNouveauDossierPapier', {
                    publication_id: parseInt(this.$route.params.publication_id),
                    payload: payload
                })
            const dossier: DossierInscription = response_post.data.data

            // Rechargement de la liste des dossiers et redirection
            const response_get = await this.$store.dispatch('dossierinscription/getDossiersInscription', {
                publication_id: parseInt(this.$route.params.publication_id),
                payload: this.$store.getters['dossierinscription/params']
            })
            if (response_get.status === 200) {
                await this.$router.push(`/dossiers_inscriptions/${this.$route.params.publication_id}/${dossier.id}`)
                this.modale = false
            }
        } finally {
            this.loading = false
        }
    }
}
