














































































































































import { Component, Vue } from 'vue-property-decorator'
import Back from '@/components/Tools/Back.vue'
import Formulaire from '@exatech-group/formulaire/src/Formulaire.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { DossierInscription, Etat, getEtatDossier } from '@/types/DossierInscription'
import { formatDate } from '@/utils/helpers'
import EditStatutDossier from '@/components/Inscriptions/EditStatutDossier.vue'
import { mapGetters } from 'vuex'
import _ from "lodash";

@Component({
    methods: { getEtatDossier, formatDate },
    components: {
        EditStatutDossier,
        Formulaire,
        Back,
        FontAwesomeIcon
    },
    computed: {
        etat() {
            return Etat
        },
        ...mapGetters({
            can: 'auth/can',
            dossier: 'dossierinscription/dossier',
            meta: 'dossierinscription/meta'
        }),
        loaded(): boolean {
            const dossier: DossierInscription = this.$store.getters['dossierinscription/dossier']
            return !!(
                !this.$data.refresh &&
                !_.isEmpty(dossier) &&
                dossier.publication &&
                dossier.compteCandidat
            )
        },
        concours(): string {
            if (!_.isEmpty(this.$store.getters['dossierinscription/dossier'])) {
                return this.$store.getters['dossierinscription/dossier'].publication.entity.concours
                    .map((concour: any) => `${concour.code} | ${concour.name}`)
                    .join(', ')
            }
            return ''
        },
        pagination(): { index: number | null; before: number; after: number } {
            const dossiers: DossierInscription[] = this.$store.getters['dossierinscription/dossiers']
            const index: number =
                dossiers.findIndex(
                    (d: DossierInscription) => d.id === this.$store.getters['dossierinscription/dossier'].id
                ) ?? -1

            return {
                index: index === -1 ? null : index + 1,
                before: index === -1 ? 0 : dossiers[index - 1] ? dossiers[index - 1].id : 0,
                after: index === -1 ? 0 : dossiers[index + 1] ? dossiers[index + 1].id : 0
            }
        }
    },
    watch: {
        '$route.params.dossier_id': {
            handler: 'reloadFormulaire',
            immediate: true
        },
        'dossier.datas': {
            handler: 'updateValuesFormulaire',
            deep: true
        }
    }
})
export default class EditionFormulaire extends Vue {
    statut: Etat | null = null
    edit = false
    datas: any = ''
    check_validation = false
    validation = false
    loading = false
    refresh = false

    /**
     * loadCurrentDossier
     * Récupère le dossier courant depuis la liste des dossiers
     * @returns {Promise<void>}
     */
    async loadCurrentDossier(): Promise<void> {
        const dossiers: DossierInscription[] = this.$store.getters['dossierinscription/dossiers']
        const meta: any = this.$store.getters['dossierinscription/meta']

        // Vérifie si toutes les données sont présentes
        if (!dossiers || !dossiers.length || !meta || isNaN(parseInt(this.$route.params.dossier_id))) {
            await this.$router.push(`/dossiers_inscriptions/${this.$route.params.publication_id}`)
            return Promise.resolve()
        }

        // Charge le dossier courant
        this.refresh = true
        await this.$store.dispatch('dossierinscription/getDossierInscription', {
            dossier_id: parseInt(this.$route.params.dossier_id)
        })

        if (!this.$store.getters['dossierinscription/dossier'].datas) {
            this.edit = true
        }

        // Vérifie s'il faut charger des dossiers supplémentaires
        if (meta.current_page < meta.last_page) {
            if (
                dossiers.findIndex((d: DossierInscription) => d.id === parseInt(this.$route.params.dossier_id)) >=
                dossiers.length - 2
            ) {
                await this.$store.dispatch('dossierinscription/getMoreDossiersInscription', {
                    publication_id: parseInt(this.$route.params.publication_id),
                    payload: {
                        ...this.$store.getters['dossierinscription/params'],
                        page: meta.current_page + 1
                    }
                })
            }
        }

        this.$nextTick(() => {
            this.refresh = false
            return Promise.resolve()
        })
    }

    /**
     * reloadFormulaire
     * Recharge l'affichage du formulaire lorsqu'on change de dossier
     * @param {boolean} force_reload - Force le rechargement du formulaire
     * @returns {void}
     */
    reloadFormulaire(force_reload = false): void {
        this.loading = false
        this.statut = null
        this.edit = false
        this.statut = null

        // Vérifie si les valeurs du formulaire sont différentes
        if (
            force_reload ||
            JSON.stringify(this.$store.getters['dossierinscription/dossier'].datas) !== JSON.stringify(this.datas)
        ) {
            this.loadCurrentDossier()
        }
    }

    /**
     * updateValuesFormulaire
     * Met à jour les informations du formulaire du dossier
     * @param {string} values - Valeurs des champs du formulaire
     * @returns {void}
     */
    updateValuesFormulaire(values: any): void {
        this.datas = typeof values === 'string' ? JSON.parse(values) : values

        // Supprime les valeurs vides des champs de type input
        if (this.datas && this.datas.length) {
            for (let i = 0; i < this.datas.length; i++) {
                if (
                    this.datas[i].type === 'input' &&
                    typeof this.datas[i].value === 'string' &&
                    this.datas[i].value.trim() === ''
                ) {
                    this.datas[i].value = null
                }
            }
        }

        if (this.loading && this.edit) {
            this.loading = false
        }
    }

    /**
     * validationHandler
     * Met à jour le statut de validation du formulaire
     * @param {boolean} statut - Statut de validation du formulaire
     * @returns {void}
     */
    validationHandler(statut: boolean): void {
        this.validation = statut
    }

    /**
     * getDocument
     * Cette fonction récupère un document à partir de son identifiant.
     * @param {any} document_id - Identifiant du document à récupérer
     * @returns {Promise<any>} Une promesse résolue avec le document récupéré
     */
    async getDocument(document_id: any): Promise<any> {
        return Promise.resolve(
            await this.$store.dispatch('dossierinscription/getDocument', {
                dossier_inscription_id: this.$store.getters['dossierinscription/dossier'].id,
                document_id: document_id
            })
        )
    }

    /**
     * getDocumentReference
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} document_id - Identifiant du document à récupérer
     * @returns {Promise<any>} Une promesse résolue avec le document récupéré
     */
    async getDocumentReferenceOld(document_id: any): Promise<any> {
        return Promise.resolve(
            await this.$store.dispatch('dossierinscription/getDocumentReference', {
                dossier_inscription_id: this.$store.getters['dossierinscription/dossier'].id,
                document_uuid: document_id
            })
        )
    }


    /**
     * getDocumentReference
     * Cette fonction récupère un document référence à partir de son identifiant.
     * @param {any} documentId - Identifiant du document à récupérer
     * @returns une promesse résolue avec le document récupéré
     */
    getDocumentReference(documentId: any) {
        return new Promise((resolve) => {
            this.$store
                .dispatch('formulaire/getDocumentReference', {
                    formulaire_id: this.$store.getters['dossierinscription/dossier'].publication.entity_id, // this.formulaireTemp.id,
                    documentId: documentId
                })
                .then((response: any) => {
                    resolve(response)
                })
        })
    }

    /**
     * storeDocument
     * Cette fonction permet de stocker un document dans le formulaire en cours.
     * @param {string} titre - Titre du document
     * @param {any} data - Données du document à stocker
     * @returns {Promise<any>} Une promesse résolue avec l'id du document stocké
     */
    async storeDocument(titre: string, data: any): Promise<any> {
        this.loading = true
        const formdata = new FormData()
        formdata.set('document', data)
        formdata.set('name', titre)

        const response = await this.$store.dispatch('dossierinscription/postDocument', {
            payload: formdata,
            dossier_inscription_id: this.$store.getters['dossierinscription/dossier'].id
        })
        return Promise.resolve({ id: response.data.data.uuid })
    }

    /**
     * deleteDocument
     * Cette fonction permet de supprimer un document à partir de son identifiant.
     * @param {any} document_uuid - Identifiant du document à supprimer
     * @returns {Promise<any>} Une promesse résolue avec la réponse de la suppression du document
     */
    async deleteDocument(document_uuid: any): Promise<any> {
        this.loading = true

        return Promise.resolve(
            await this.$store.dispatch('dossierinscription/deleteDocument', {
                dossier_inscription_id: this.$store.getters['dossierinscription/dossier'].id,
                document_uuid: document_uuid
            })
        )
    }

    /**
     * updateDatasDossier
     * Met à jour le dossier
     * @returns {void}
     */
    async updateDatasDossier(): Promise<void> {
        if (this.loading) {
            return Promise.resolve()
        }
        this.loading = true

        const dossier: DossierInscription = JSON.parse(
            JSON.stringify(this.$store.getters['dossierinscription/dossier'])
        )
        if (this.datas && JSON.stringify(dossier.datas) !== JSON.stringify(this.datas)) {
            this.check_validation = false
            this.$nextTick(() => {
                this.check_validation = true
            })

            if (!this.validation) {
                this.loading = false
                return
            }

            dossier.datas = this.datas
            try {
                await this.$store.dispatch('dossierinscription/putFormulaireDossierInscription', {
                    dossier_inscription_id: dossier.id,
                    payload: {
                        datas: dossier.datas
                    }
                })
            } finally {
                this.reloadFormulaire(true)
            }
        } else {
            this.loading = false
            this.edit = false
        }
    }
}
