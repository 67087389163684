


























import { Component, Vue } from 'vue-property-decorator'
import { dateIsBetween, dateNow, formatDateHoursMinutes, getDayMonthYear } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { PublicationInterface } from '@/types/Publication'
import {mapGetters} from "vuex";

@Component({
    components: {
        ExaGenericTable
    },
    computed: {
        ...mapGetters({
            publications: 'publication/get_publications_inscriptions',
            loading: 'publication/get_loading',
            loading_dossiers: 'dossierinscription/loading'
        })
    },
    watch: {
        publications: {
            handler: "onPublicationsChange",
            deep: true,
            immediate: true
        }
    }
})

export default class Formulaires extends Vue {
    datas: any[] = []
    filters: any[] = []
    fields = [
        { key: 'name', label: 'Libellé', sortable: false, class: '', type: 'text' },
        {
            key: 'visibility_start_at',
            label: 'Programmation de la visibilité',
            sortable: false,
            class: '',
            type: 'text'
        },
        { key: 'start_at', label: 'Programmation des accès', sortable: false, class: '', type: 'text' },
        { key: 'opened', label: 'Blocage', sortable: true, sortDirection: 'asc', class: 'text-center', type: 'text' },
        {
            key: 'dossier_inscriptions_count',
            label: 'Dossiers',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center',
            type: 'text'
        },
        { key: 'open', label: '', sortable: false, class: '', type: 'action' }
    ]

    /**
     * onPublicationsChange
     * Construction du tableau de données à afficher
     * @param {PublicationInterface[]} publications - Publications à afficher
     * @returns {void}
     */
    onPublicationsChange(publications: PublicationInterface[]): void {
        this.datas = []
        if (!publications || !publications.length) {
            return
        }

        // Affichage de l'état des programmations
        const getProg = (index: number, target: 'access' | 'visibility' = 'access') => {
            let start_at: Date | number | string = publications[index].start_at
            let end_at: Date | number | string = publications[index].end_at
            if (target === 'visibility') {
                start_at = publications[index].visibility_start_at
                end_at = publications[index].visibility_end_at
            }
            if (!start_at && !end_at) {
                return { libelle: ['-'], color: 'text-secondary' }
            } else {
                const is_between: boolean = dateIsBetween(dateNow(), start_at, end_at, [])
                return {
                    libelle: [
                        `${getDayMonthYear(start_at)} (${formatDateHoursMinutes(start_at)})`,
                        `${getDayMonthYear(end_at)} (${formatDateHoursMinutes(end_at)})`
                    ],
                    color: is_between ? 'text-success' : 'text-secondary'
                }
            }
        }

        // Construction du tableau de données à afficher
        for (let index = 0; index < publications.length; index++) {
            // "..." ajouté en fin de libellé s'il est trop long
            if (publications[index].name.length >= 48) {
                publications[index].name = publications[index].name.substring(0, 48) + '...'
            }

            this.datas.push([
                { label: '', item: publications[index].name, type: 'text', typeAction: null, class: '' },
                {
                    label: '',
                    item: getProg(index, 'visibility').libelle.join(' - '),
                    type: 'text',
                    typeAction: null,
                    class: getProg(index, 'visibility').color
                },
                {
                    label: '',
                    item: getProg(index).libelle.join(' - '),
                    type: 'text',
                    typeAction: null,
                    class: getProg(index).color
                },
                {
                    label: publications[index].opened ? 'Ouvert' : 'Bloqué',
                    item: publications[index].opened,
                    icon: publications[index].opened ? 'lock-open-alt' : 'lock-alt',
                    type: 'action',
                    typeAction: null,
                    class: `text-center ${publications[index].opened ? 'text-tertiary' : 'text-danger'} pe-none`
                },
                {
                    label: '',
                    item: publications[index].dossier_inscriptions_count
                        ? publications[index].dossier_inscriptions_count
                        : '-',
                    type: 'text',
                    typeAction: null,
                    class: 'text-center'
                },
                {
                    label: 'Voir des dossiers',
                    item: publications[index].id,
                    icon: 'arrow-circle-right',
                    type: 'action',
                    typeAction: 'open',
                    class: 'text-center text-primary'
                }
            ])
        }
    }

    /**
     * loadPublications
     * Charge les publications dans le store
     * @returns {Promise<void>}
     */
    async loadPublications(): Promise<void> {
        await this.$store.dispatch('publication/getPublications')
        return Promise.resolve()
    }

    /**
     * loadFilters
     * Chargement des filtres
     * @returns {void}
     */
    loadFilters(): void {
        this.filters = [
            {
                libelle: 'Libellé',
                model: 'name',
                value: '',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'name', strict: false }
            },
            {
                libelle: 'Blocage',
                model: 'opened',
                value: '',
                datas: [
                    { index: 0, name: 'Bloqué' },
                    { index: 1, name: 'Ouvert' }
                ],
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'opened', strict: true }
            }
        ]

        // Récupération des filtres enregistrés dans le store
        const params = this.$store.getters['publication/get_params']
        for (let i = 0; i < this.filters.length; i++) {
            let filter = params[`filter-${this.filters[i].model}`]
            if (filter) {
                filter = this.filters[i].options.strict ? filter : filter.substring(1, filter.length - 1)
                this.filters[i].value = this.filters[i].options.type === 'deroulant' ? parseInt(filter) : filter
            }
        }
    }

    /**
     * handleTableEvent
     * Récupération des events de la table
     * @param {any} paParams - Paramètres de l'événement
     * @returns {void}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    if (JSON.stringify(this.$store.getters['publication/get_params']) !== JSON.stringify(paParams[1])) {
                        this.$store.commit('publication/SET_PARAMS', paParams[1])
                        await this.loadPublications()
                    }
                    break
                case 'open': {
                    this.$store.commit('dossierinscription/RESET_PARAMS')
                    await this.$store.dispatch('dossierinscription/getDossiersInscription', {
                        publication_id: parseInt(paParams[1]),
                        payload: this.$store.getters['dossierinscription/params']
                    })
                    await this.$router.push(`/dossiers_inscriptions/${paParams[1]}`)
                    break
                }
                default:
                    break
            }
        }
    }

    /**
     * beforeMount
     * Séquence de chargement des données avant le montage du composant
     * @returns {Promise<void>}
     */
    async beforeMount(): Promise<void> {
        await this.loadPublications()
        this.loadFilters()
    }
}
